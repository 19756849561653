import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SelfcareBillsService } from '@yol-digital/ms-client';
import { catchError, map, of, switchMap } from 'rxjs';
import { ToastService } from 'toast';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { TranslateService } from 'translate';
import { ENVIRONMENT_URLS_CONFIG_TOKEN, EnvironmentUrlsConfig, ObservableCachedDataLoaderService } from 'utils';
import { UserService } from './user.service';
import Api = SelfcareBillsService.Api;

@Injectable({
  providedIn: 'root',
})
export class BillsService {
  private api: Api;
  private http = inject(HttpClient);
  private userService = inject(UserService);
  private cachedDataLoader = inject(ObservableCachedDataLoaderService);
  private translateService = inject(TranslateService);
  private toasts = inject(ToastService);
  private config = inject<EnvironmentUrlsConfig>(ENVIRONMENT_URLS_CONFIG_TOKEN);

  constructor() {
    this.api = new Api(this.config.newMicroServiceEndpoint, this.http);
  }

  public getInvoices() {
    return this.userService.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) return of(null);
        return this.cachedDataLoader.get('invoices', () =>
          this.api.public.getOpenInvoices().pipe(
            map(invoices => {
              if ('error' in invoices) {
                throw new Error(invoices.error);
              } else {
                return invoices;
              }
            }),
            catchError(err => {
              if (!err?.wasCaught) {
                console.error(err);
                this.toasts.add(this.translateService.getTranslation(['server_error']), false);
              }
              return of(null);
            })
          )
        );
      })
    );
  }
}
